<template>
  <a-modal
    v-drag-modal
    :title="title"
    :visible="visible"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    :width="500"
    @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" v-if="visible">
      <a-form-model-item label="认证状态" v-bind="formItemLayout">
        <a-radio-group v-model="form.authFlag" :defaultValue="form.authFlag">
          <a-radio :value="0">新人</a-radio>
          <a-radio :value="1">待认证</a-radio>
          <a-radio :value="2">已认证</a-radio>
          <a-radio :value="-1">审核未通过</a-radio>
        </a-radio-group>
      </a-form-model-item>
<!--      <a-form-model-item label="是否协助" v-bind="formItemLayout">-->
<!--        <a-radio-group v-model="form.helpAuth" :defaultValue="form.helpAuth">-->
<!--          <a-radio :value="0">不允许</a-radio>-->
<!--          <a-radio :value="1">允许</a-radio>-->
<!--        </a-radio-group>-->
<!--      </a-form-model-item>-->
    </a-form-model>
    <template slot="footer">
      <div class="modal-btn">
        <a-button type="primary" :loading="confirmLoading" @click="handleOk(1)">
          确定
        </a-button>
        <a-button type="danger" :loading="confirmLoading" @click="handleOk(2)">
          取消
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: "settingModal",
  data() {
    return {
      visible: false,
      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 15 },
      },
      title: "",
      confirmLoading: false,
      form: {
        authFlag: 0,
        helpAuth: 0,
      },
    };
  },
  methods: {
    showModal(record) {
      this.visible = true;
      this.form = { ...record };
      this.title = "用户ID：" + record.uid + "设置";
    },
    handleOk(index) {
      if (index == 2) {
        this.visible = false;
        return;
      }
      this.confirmLoading = true;
      this.$postJson("user/updateUser", this.form)
        .then((res) => {
          this.confirmLoading = false;
          this.visible = false;
          this.$emit("success");
        })
        .catch((error) => {
          this.confirmLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.modal-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
